.audio-controls {
	display: flex;
	margin-bottom: 1.25rem;
	justify-content: center;
	align-items: stretch;
}

.audio-controls__button {
	-moz-osx-font-smoothing: inherit;
	-webkit-appearance: none;
	-webkit-font-smoothing: inherit;
	background-color: var(--color-alpha-medium-yellow);
	border: none;
	cursor: pointer;
	line-height: normal;
	margin: 0;
	outline: none;
	overflow: visible;
	padding: 0;
	transition: background-color 96ms linear;
	width: 3.4375rem;
	min-height: 3.4375rem;
}

.audio-controls__button.audio-controls__button--play {
	position: relative;
}

.audio-controls__button:hover,
.audio-controls__button:focus {
	background-color: var(--color-alpha-dark-yellow);
}

.audio-controls__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.audio-controls__icon.audio-controls__icon--untoggled {
	width: 14px;
	height: 20px;
}

.audio-controls__icon.audio-controls__icon--toggled {
	display: none;
	width: 16px;
	height: 20px;
}

.is-playing .audio-controls__icon.audio-controls__icon--untoggled {
	display: none;
}

.is-playing .audio-controls__icon.audio-controls__icon--toggled {
	display: block;
}

.audio-controls__icon .icon {
	width: 100%;
	height: 100%;
}

.audio-controls__progress {
	background-color: var(--color-alpha-light-yellow);
	padding: 1rem 1.25rem;
	min-width: 126px;
}

.audio-controls__progress__label {
	display: block;
	position: relative;
	min-width: 126px;
	height: 100%;
}

.audio-controls__progress__current,
.audio-controls__progress__duration,
.audio-controls__progress__slash {
	position: absolute;
	top: 50%;
}

.audio-controls__progress__current::before,
.audio-controls__progress__duration::before {
	content: attr(data-text);
}

.audio-controls__progress__current {
	left: 0;
	transform: translate(0, -50%) translateZ(0);
}

.audio-controls__progress__slash {
	left: 50%;
	transform: translate(-50%, -50%) translateZ(0);
}

.audio-controls__progress__duration {
	right: 0;
	transform: translate(0, -50%) translateZ(0);
}
