@media (--mq-up-to-small) {
	.l-site {
		display: flex;
		flex-direction: column-reverse;
		min-height: calc(100vh - 56px);
	}
}
/* account for iPhone X notch */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
	.l-site {
		min-height: calc(100vh - 56px - 56px);
	}
}
