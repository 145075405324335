/* Gutenberg Stylings */
.wp-block-separator {
	background-color: var(--color-black);
	border: none;
	margin: 2.5rem auto;
	max-width: 6.25rem;
	height: 1px;
}

.wp-block-separator.is-style-dots {
	background: none;
	line-height: 1;
	text-align: center;
	max-width: none;
	height: auto;
}

.wp-block-separator.is-style-dots::before {
	color: var(--color-black);
	content: "\00b7 \00b7 \00b7";
	font-size: 1.25rem;
	letter-spacing: 2rem;
	padding-left: 1.25rem;
}

.wp-block-separator.is-style-wide {
	max-width: none;
}

.wp-block-quote cite {
	font-style: italic;
}

@media (min-width: 768px) {
	.wp-block-quote.is-style-large p {
		font-size: 2em;
	}

	.wp-block-quote.is-style-large cite {
		font-size: 0.625em;
	}
}

.wp-block-image {
	display: inline;
}

.wp-block-image figure {
	margin-bottom: 0;
}

.wp-block-image figure.alignleft,
.wp-block-image figure.aligncenter,
.wp-block-image figure.alignright {
	width: calc(50% - 0.3125rem);
}

@media (min-width: 768px) {
	.wp-block-image figure.alignleft,
	.wp-block-image figure.aligncenter,
	.wp-block-image figure.alignright {
		width: calc(50% - 0.625rem);
	}
}

.wp-block-image figure.alignleft {
	display: inline-block;
	float: left;
	margin-right: 0.625rem;
}

@media (min-width: 768px) {
	.wp-block-image figure.alignleft {
		margin-right: 1.25rem;
	}
}

.wp-block-image figure.aligncenter {
	margin: 0 auto;
}

.wp-block-image figure.alignright {
	display: inline-block;
	float: right;
	margin-left: 0.625rem;
}

@media (min-width: 768px) {
	.wp-block-image figure.alignright {
		margin-left: 1.25rem;
	}
}

.wp-block-image img {
	width: 100%;
}

.wp-block-image figcaption {
	display: block;
	margin-top: 0.625rem;
	margin-bottom: 1.25rem;
}

@media (max-width: 767px) {
	figure.alignleft + .wp-block-image figcaption,
	figure.aligncenter + .wp-block-image figcaption,
	figure.alignright + .wp-block-image figcaption {
		margin-bottom: 0.625rem;
	}
}

.wp-block-quote.is-style-large {
	margin-right: 0;
}

.wp-block-embed {
	display: block;
	width: 100%;
}

.wp-block-embed.wp-has-aspect-ratio {
	position: relative;
}

.wp-block-embed.wp-has-aspect-ratio.wp-embed-aspect-1-1 {
	padding-top: 100%;
}

.wp-block-embed.wp-has-aspect-ratio.wp-embed-aspect-16-9 {
	padding-top: 56.25%;
}

.wp-block-embed.wp-has-aspect-ratio.wp-embed-aspect-4-3 {
	padding-top: 75%;
}

.wp-block-embed.wp-has-aspect-ratio.wp-embed-aspect-21-9 {
	padding-top: 42.857%;
}

.wp-block-embed.wp-has-aspect-ratio iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
