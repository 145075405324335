@font-face {
	font-family: "Fat Frank";
	src: url("../fonts/FatFrank-Regular.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Playfair Display";
	src: url("../fonts/playfairdisplay-regular-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Playfair Display";
	src: url("../fonts/playfairdisplay-italic-webfont.woff2") format("woff2");
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "Playfair Display";
	src: url("../fonts/playfairdisplay-bold-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "Playfair Display SC";
	src: url("../fonts/playfairdisplaysc-regular-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}
