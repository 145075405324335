.overlay {
	background-color: var(--color-white);
	box-sizing: border-box;
	margin-top: 3.125rem;
	padding: 1.25rem;
	position: relative;
	z-index: 3;
}

@media (--mq-from-small) {
	.overlay {
		margin-top: 1.25rem;
		width: 66vw;
	}
}

@media (--mq-from-medium) {
	.overlay {
		width: 75vw;
	}
}

@media (--mq-from-wide) {
	.overlay {
		margin-left: calc(75vw - 1080px);
		max-width: 1080px;
	}
}

@media (--mq-from-large) {
	.overlay {
		margin-top: 2.5rem;
		padding: 2.5rem;
	}
}

@media (--mq-from-xlarge) {
	.overlay {
		margin-left: calc(50vw - 540px);
	}
}

.overlay__wrapper {
	/* 100vh - padding-top overlay - padding-bottom overlay - margin-top overlay */
	min-height: calc(100vh - 1.25rem - 1.25rem - 3.125rem);
}

@media (--mq-from-small) {
	.overlay__wrapper {
		min-height: calc(100vh - 1.25rem - 1.25rem - 1.25rem);
	}
}

@media (--mq-from-large) {
	.overlay__wrapper {
		min-height: calc(100vh - 2.5rem - 2.5rem - 2.5rem);
	}
}

.overlay__close {
	color: var(--color-black);
	position: absolute;
	top: 0;
	right: 0;
	transition: color 96ms linear;
	width: 3.5rem;
	height: 3.5rem;
}

.overlay__close:focus,
.overlay__close:hover {
	color: var(--color-grey);
}

.overlay__close:focus {
	outline: none;
}

@media (--mq-from-small) {
	.overlay__close {
		width: 3.75rem;
		height: 3.75rem;
	}
}

@media (--mq-from-large) {
	.overlay__close {
		width: 6.25rem;
		height: 6.25rem;
	}
}

.overlay__close .icon-close {
	display: block;
	position: absolute;
	top: 1.25rem;
	right: 1.25rem;
	transform: translate(-2px, 2px);
	width: 1rem;
	height: 1rem;
}

@media (--mq-from-small) {
	.overlay__close .icon-close {
		width: 1.25rem;
		height: 1.25rem;
	}
}

@media (--mq-from-large) {
	.overlay__close .icon-close {
		top: 2.5rem;
		right: 2.5rem;
		transform: translate(-4px, 4px);
	}
}
