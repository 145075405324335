.l-styleguide .site-header {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100vh;
}

.site-header__wrapper {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: 1.25rem;
	align-items: flex-end;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}

.l-styleguide .site-header__wrapper {
	position: relative;
}

@media (--mq-from-small) {
	.site-header__wrapper {
		height: 100vh;
	}
}

@media (--mq-from-large) {
	.site-header__wrapper {
		padding: 2.5rem;
	}
}

.site-header__top-container {
	display: flex;
}

@media (--mq-up-to-small) {
	.site-header__top-container {
		justify-content: space-between;
		width: 100%;
	}
}

@media (--mq-from-small) {
	.site-header__top-container {
		flex-direction: column;
		align-items: flex-end;
	}
}

.site-header__logo {
	color: var(--color-black);
	width: 93px;
	height: 89px;
}

@media (--mq-from-small) {
	.site-header__logo {
		margin-bottom: 1.25rem;
		width: 157px;
		height: 140px;
	}
}

.site-header__description-container {
	max-width: 145px;
}

@media (--mq-up-to-small) {
	.site-header__description-container {
		font-size: 0.8125rem;
		order: -1;
	}
}

@media (--mq-from-small) {
	.site-header__description-container {
		text-align: right;
		max-width: 180px;
	}
}

.site-header__description-container > *:not(:last-child) {
	margin-bottom: 1rem;
}

.site-header__language-picker {
	display: inline-flex;
	justify-content: flex-start;
	align-items: flex-start;
}

@media (--mq-from-small) {
	.site-header__language-picker {
		justify-content: flex-end;
	}
}

.site-header__language-picker > *:not(:last-child) {
	margin-right: 0.375rem;
}

.site-header__language-picker__item {
	margin-top: 0;
	margin-bottom: 0;
}

a.site-header__language-picker__item {
	color: currentColor;
}

a.site-header__language-picker__item:focus,
a.site-header__language-picker__item:hover {
	text-decoration: none;
}

.site-header__bottom-container {
	z-index: 2;
}

@media (--mq-up-to-small) {
	.site-header__bottom-container {
		box-sizing: border-box;
		display: block;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		position: relative;
		text-align: right;
	}
}

@media (--mq-from-small) {
	.site-header__bottom-container {
		position: fixed;
		right: 1.25rem;
		bottom: 1.25rem;
		max-width: calc(34vw - 2.5rem);
	}
}

@media (--mq-from-medium) {
	.site-header__bottom-container {
		max-width: calc(25vw - 2.5rem);
	}
}

@media (--mq-from-large) {
	.site-header__bottom-container {
		right: 2.5rem;
		bottom: 2.5rem;
		max-width: calc(25vw - 5rem);
	}
}

@media (--mq-from-xlarge) {
	.site-header__bottom-container {
		max-width: calc(50vw - 540px - 5rem);
	}
}

@media (--mq-from-small) {
	.l-styleguide .site-header__bottom-container {
		position: absolute;
	}
}

.site-header__background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.site-header__background::after {
	background: radial-gradient(circle, rgb(222 210 187 / 0) 0%, rgb(222 210 187 / 1) 90%);
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.l-styleguide .site-header__background {
	position: absolute;
}

.site-header__background__noise {
	background-image: url("../images/noise.gif");
	background-size: 125px 125px;
	width: 100%;
	height: 100%;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.site-header__background__noise {
		background-size: 63px 63px;
	}
}

.site-header__background__mask {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale3d(2, 2, 1);
	width: 100vh;
	max-width: none;
	height: 100vh;
}

@media (--mq-from-wide) {
	.site-header__background__mask {
		transform: translate(-50%, -50%) scale3d(1.2, 1.2, 1);
	}
}

@media (min-aspect-ratio: 1/1) {
	.site-header__background__mask {
		width: 100vw;
		height: 100vw;
	}
}
