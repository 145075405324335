.l-error {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	justify-content: center;
	align-items: center;

	/* 100vh - padding-top overlay - padding-bottom overlay - margin-top overlay */
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	min-height: calc(100vh - 1.25rem - 1.25rem - 3.125rem);
}

@media (--mq-from-small) {
	.l-error {
		min-height: calc(100vh - 1.25rem - 1.25rem - 1.25rem);
	}
}

@media (--mq-from-large) {
	.l-error {
		padding-top: 3.75rem;
		padding-bottom: 3.75rem;
		min-height: calc(100vh - 2.5rem - 2.5rem - 2.5rem);
	}
}

.l-error__content,
.l-error__header {
	text-align: center;
	max-width: 500px;
}

.l-error__header + .l-error__content {
	margin-top: 1rem;
}

@media (--mq-from-small) {
	.l-error__header + .l-error__content {
		margin-top: 1.25rem;
	}
}
