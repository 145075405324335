.hyperlink {
	border-bottom: 1px currentColor solid;
	color: var(--color-grey);
	display: flex;
	padding-bottom: 0.625rem;
	align-items: center;
	text-decoration: none;
	transition: color 96ms linear;
}

.hyperlink:hover,
.hyperlink:focus {
	color: var(--color-black);
}

.hyperlink:focus {
	outline: 0;
}

.hyperlink .icon-arrow {
	width: 6px;
	height: 12px;
}

.hyperlink__label {
	flex-grow: 1;
}
