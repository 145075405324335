body {
	background-color: var(--color-yellow);
	box-sizing: border-box;
	color: var(--color-black);
}

.l-styleguide {
	background-color: var(--color-white);
}

textarea {
	resize: none;
}

hr {
	background-color: var(--color-black);
	border: none;
	height: 1px;
}

img,
video {
	display: block;
	max-width: 100%;
}

table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	margin: 2rem 0;
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th,
td {
	box-sizing: border-box;
	padding: 2rem;
	text-align: left;
}

ol:not([class]),
ul:not([class]) {
	box-sizing: border-box;
	margin: 0 0 2rem 0;
}

ol:not([class]) ol:not([class]),
ol:not([class]) ul:not([class]),
ul:not([class]) ol:not([class]),
ul:not([class]) ul:not([class]) {
	padding-left: 2rem;
}

ol:not([class]):last-child,
ul:not([class]):last-child {
	margin-bottom: 0;
}

li ol:not([class]),
li ul:not([class]) {
	margin-bottom: 0;
}

ul:not([class]) {
	list-style: none;
	padding-left: 0;
}

ol:not([class]) {
	padding-left: 1.25rem;
}

li:not([class]) {
	margin: 0;
	padding: 0;
	position: relative;
}

ul:not([class]) li:not([class])::before {
	content: "• ";
}

p:first-child {
	margin-top: 0;
}

p:last-child {
	margin-bottom: 0;
}

p:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

figure {
	display: block;
	margin: 0;
	margin-bottom: 1.25rem;
}

blockquote {
	margin: 1.5625rem 0;
}

@media (--mq-from-small) {
	blockquote {
		margin: 1.5625rem 1.25rem;
	}
}
