:root {
	--color-white: #fffffe;
	--color-grey: #7e807d;
	--color-black: #1a1919;
	--color-yellow: #ded2bb;
	/*  */
	--color-alpha-light-yellow: rgb(223 211 187 / 0.1);
	--color-alpha-medium-yellow: rgb(223 211 187 / 0.3);
	--color-alpha-dark-yellow: rgb(223 211 187 / 0.7);
	/*  */
	--font-sans: "Fat Frank", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	--font-serif: "Playfair Display", serif;
	--font-serif-sc: "Playfair Display SC", serif;
}
