@media (--mq-from-small) {
	.l-page {
		display: grid;
		grid-gap: 1.25rem 3.75rem;
		grid-template-areas:
			"header  header"
			"content sidebar";
		grid-template-columns: 2fr 1fr;
	}
}

.l-page__header {
	grid-area: header;
}

.l-page__title {
	max-width: calc(100% - 2rem);
	min-height: 1rem;
}

@media (min-width: 565px) and (max-width: 768px) {
	.l-page__title {
		max-width: 500px;
	}
}

@media (--mq-from-small) {
	.l-page__title {
		width: calc((100% - 3.75rem) / 3 * 2);
		max-width: calc(100% - 2.5rem);
		min-height: 1.25rem;
	}
}

@media (min-width: 1080px) {
	.l-page__title {
		max-width: 500px;
	}
}

.l-page__content {
	grid-area: content;
	max-width: 500px;
}

@media (--mq-up-to-small) {
	.l-page__content {
		margin-top: 1.25rem;
	}
}

.l-page__sidebar {
	grid-area: sidebar;
}

@media (--mq-up-to-small) {
	.l-page__sidebar {
		margin-top: 1.25rem;
	}
}

.l-page__sidebar__item + .l-page__sidebar__item {
	margin-top: 1.25rem;
}
