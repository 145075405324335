.primary-navigation {
	display: inline-block;
	list-style: none;
	padding-left: 0;
	text-align: right;
}

@media (--mq-from-small) {
	.primary-navigation {
		margin: 0;
	}
}

.primary-navigation__link {
	box-sizing: border-box;
	color: var(--color-black);
	display: inline-block;
	line-height: 1.875rem;
	position: relative;
	text-decoration: none;
	transition: padding 208ms ease-in-out;
	height: 1.875rem;
}

.current-menu-item .primary-navigation__link,
.primary-navigation__link:hover,
.primary-navigation__link:focus {
	padding-right: 1.5rem;
}

.primary-navigation:hover .current-menu-item .primary-navigation__link:not(:hover),
.primary-navigation:focus .current-menu-item .primary-navigation__link:not(:focus) {
	padding-right: 0;
}

.primary-navigation__link:focus {
	outline: 0;
}

.primary-navigation__link::after {
	background-color: currentColor;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate3d(0, -50%, 0) scale(0, 1);
	transform-origin: center right;
	transition: transform 208ms ease-in-out;
	width: 14px;
	height: 2px;
}

.current-menu-item .primary-navigation__link::after,
.primary-navigation__link:hover::after,
.primary-navigation__link:focus::after {
	transform: translate3d(0, -50%, 0) scale(1, 1);
}

.primary-navigation:hover .current-menu-item .primary-navigation__link:not(:hover)::after,
.primary-navigation:focus .current-menu-item .primary-navigation__link:not(:focus)::after {
	transform: translate3d(0, -50%, 0) scale(0, 1);
}
