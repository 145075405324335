body {
	font-family: var(--font-serif);
	font-size: 0.9375rem;
	line-height: 1.4;
}

@media (--mq-from-small) {
	body {
		line-height: 1.6;
	}
}

* {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 1.5625rem;
	margin-bottom: 1.5625rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

h1,
h2,
.type-a {
	font-family: var(--font-sans);
	font-size: 1.375rem;
	font-weight: 400;
	letter-spacing: 1px;
	line-height: 1;
	text-transform: uppercase;
}

@media (--mq-from-small) {
	h1,
	h2,
	.type-a {
		font-size: 1.875rem;
		letter-spacing: 1.2px;
	}
}

h3,
.type-b {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	line-height: 1;
	text-transform: uppercase;
}

h4,
.type-c {
	font-family: var(--font-serif-sc);
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.57;
}

h5,
h6 {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: 400;
	letter-spacing: 0.5px;
	line-height: 1;
	text-transform: uppercase;
}

.type-audio {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	font-weight: 400;
	letter-spacing: 0.72px;
	line-height: 1.3;
	text-transform: uppercase;
}

i,
em,
blockquote {
	font-style: italic;
}

th {
	font-family: var(--font-sans);
	font-size: 0.9375rem;
	font-weight: 400;
	letter-spacing: 1px;
}

blockquote > p {
	font-size: 1.125rem;
	line-height: 1.33;
	margin-top: 0;
	margin-bottom: 0;
}

@media (--mq-from-small) {
	blockquote > p {
		font-size: 1.25rem;
		line-height: 1.2;
	}
}

blockquote > p > cite {
	color: var(--color-grey);
	font-size: 0.8125rem;
	line-height: 1.85;
}

a:not([class]) {
	border-bottom: 1px solid currentColor;
	box-sizing: border-box;
	color: var(--color-grey);
	text-decoration: none;
	transition: color 96ms linear;
}

a[href^="tel"]:not([class]) {
	color: currentColor;
	text-decoration: none;
}

a:not([class]):active,
a:not([class]):focus,
a:not([class]):hover {
	color: var(--color-black);
}

a:not([class]):focus {
	outline: 0;
}
